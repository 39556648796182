import { React } from "react";
import { BsCheck2All } from "react-icons/bs"
import { MdQueryStats, MdOutlineReadMore } from "react-icons/md"
import { AiOutlineFileSearch } from "react-icons/ai"

const cards = [
    { icon:<BsCheck2All className="w-auto m-auto h-8"/>, title: "Standardi kvalita", description: "U našem poslovnju i proizvodnji primenjujemo međunarodne standarde kvaliteta." },
    { icon:<MdQueryStats className="w-auto m-auto h-8"/>, title: "Brend", description: "Kreirali smo sopstveni brend CAMELIA koji je široko prepoznatljiv na tržištu. " },
    { icon:<AiOutlineFileSearch className="w-auto m-auto h-8"/>, title: "Istraživanje i razvoj", description: "Kontinuirano ulažemo u sopstvene proizvodne i ljudske resurse sa ciljem da našim kupcima pružimo vrhunski kvalitet." },
]

const Cards = () => {
    return (
        <>
            <div className="flex flex-col md:flex-row justify-center w-full 2xl:container 2xl:mx-auto my-10">
            {cards.map((card) => (
                                <div key={card.title} class="flex mx-auto mt-20 w-[300px] md:w-[250px] md:h-[350px] xl:w-auto">
                                        <div class="block shadow-xl bg-white max-w-sm text-center text-violet-900 lg:text-zinc-600 border-2 border-indigo-400 
                                                    hover:border-2 hover:border-indigo-500 hover:text-violet-900">
                                            <div class="py-3 px-6 border-b bg-indigo-600 overflow-hidden text-white
                                                        hover:bg-white hover:text-indigo-600 hover:border-t-0">
                                                {card.icon}
                                            </div>
                                            <div class="p-6">
                                                <h5 class="text-gray-900 text-xl font-medium mb-2">{card.title}</h5>
                                                <p class="text-gray-700 text-base mb-4 text-left">
                                                    {card.description} 
                                                </p>
                                                <a type="button" class="cursor-pointer inline-block border-2 border-white mt-auto w-full px-6 py-3.5
                                                                        bg-indigo-400 text-white font-medium text-xs leading-tight uppercase
                                                                        
                                                                        hover:bg-indigo-100 hover:border-2 hover:border-indigo-400 hover:text-indigo-600 hover:shadow-lg 
                                                                        focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 
                                                                        active:bg-indigo-700 active:shadow-lg active:text-white 
                                                                        transition duration-150 ease-in-out ">
                                                                        <MdOutlineReadMore className="m-auto w-auto h-8"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
            </div>
        </>
    )
}

export default Cards;