import React from "react";
import HeroImg from "../assets/Slider/Camelia-slider1.jpg";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, MailIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/react/outline'
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa"

const navigation = [
    { name: 'Početna', href: '#' },
    { name: 'Proizvodi', href: '#' },
    { name: 'Kompanija', href: '#' },
    { name: 'Karijera', href: '#' },
    { name: 'Vesti', href: '#' },
    { name: 'Kontakt', href: '#' },
]

const Hero = () => {
    return (
        <>
            <div className="container-full">
                <nav className='container-full bg-violet-900 text-white'>
                    <div className='flex flex m-auto justify-center p-3'>
                        <div className='flex flex-col md:flex-row h-12 mx-auto my-auto text-xl md:text-base'>
                            <div className="hidden xl:flex md:mx-auto md:mt-0 md:p-2 md:mr-2 md:border-l-2 md:hover:bg-white md:hover:text-violet-900 duration-300 transform ease-in-out hover:-transform-y-1 hover:scale-110">
                                <LocationMarkerIcon className="h-5 mt-1" />
                                <a href='https://www.google.com/maps/place/Camelia/@43.8936563,20.4374689,17z/data=!3m1!4b1!4m5!3m4!1s0x47576d925f51c1db:0x7b0e153da4eb6919!8m2!3d43.8936525!4d20.4396576'>Donja Trepča BB, 32215 Čačak </a>
                            </div>
                            <div className="hidden md:flex md:mx-auto md:mt-0 md:p-2 md:mr-2 md:border-l-2  md:hover:bg-white md:hover:text-violet-900 duration-300 transform ease-in-out hover:-transform-y-1 hover:scale-110">
                                <MailIcon className="h-5 mt-1" />
                                <a href='mailto: office@camelia.rs'>office@camelia.rs</a>
                            </div>
                            <div className="flex mx-auto md:p-2 pt-2 mr-2 md:border-l-2 md:hover:bg-white md:hover:text-violet-900 duration-300 transform ease-in-out hover:-transform-y-1 hover:scale-110">
                                <PhoneIcon className="h-8 w-auto md:h-5 md:mt-1" />
                                <a href='tel: +381 32 406 888'><span className="hidden sm:inline-block">Telefon:</span>+381 32 406 888</a>
                            </div>
                        </div>
                        <div className='flex mx-auto my-auto mx-auto space-x-2'>
                            <a href="https://www.facebook.com/LPBPromet/"><FaFacebookF size={20} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 duration-200 bg-white md:bg-violet-900 md:text-white text-violet-900 rounded p-2 h-9 w-auto"/></a>
                            <a href="https://rs.linkedin.com/company/lpbprometdoo?trk=similar-pages&original_referer=https%3A%2F%2Fcamelia.goldensolution.rs%2F"><FaLinkedinIn size={20} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 duration-200 bg-white md:bg-violet-900 md:text-white text-violet-900 rounded p-2 h-9 w-auto"/></a>
                            <a href="https://www.instagram.com/camelia_cacak/?igshid=YmMyMTA2M2Y="><FaInstagram size={20} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 duration-200 bg-white md:bg-violet-900 md:text-white text-violet-900 rounded p-2 h-9 w-auto"/></a>
                        </div>
                    </div>
                </nav>
                <div className="relative bg-white overflow-hidden">
                    <div className="max-w-7xl mx-auto">
                        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                            <svg
                                className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                                fill="currentColor"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="none"
                                aria-hidden="true"
                            >
                                <polygon points="50,0 100,0 50,100 0,100" />
                            </svg>

                            <Popover>
                                <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                                    <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                                        <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                            <div className="flex items-center justify-between w-full md:w-auto">
                                                <a href="#">
                                                    <span className="sr-only">LPB</span>
                                                    <img
                                                        alt="LPB"
                                                        className="h-16 w-auto lg:h-20 xs:h-10"
                                                        src="/lpb.png"
                                                    />
                                                </a>
                                                <div className="-mr-2 flex items-center md:hidden">
                                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                        <span className="sr-only">Open main menu</span>
                                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                                    </Popover.Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="hidden sticky md:block md:ml-10 md:pr-4 md:space-x-8">
                                            {navigation.map((item) => (
                                                <a key={item.name} href={item.href} className="font-medium text-bold text-gray-500 hover:text-indigo-900 hover">
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </nav>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="duration-150 ease-out"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="duration-100 ease-in"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Popover.Panel
                                        focus
                                        className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                                    >
                                        <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                            <div className="px-5 pt-4 flex items-center justify-between">
                                                <div>
                                                    <img
                                                        className="h-8 w-auto"
                                                        src="/lpb.png"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="-mr-2">
                                                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                        <span className="sr-only">Close main menu</span>
                                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                                    </Popover.Button>
                                                </div>
                                            </div>
                                            <div className="px-2 pt-2 pb-3 space-y-1">
                                                {navigation.map((item) => (
                                                    <a
                                                        key={item.name}
                                                        href={item.href}
                                                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                                    >
                                                        {item.name}
                                                    </a>
                                                ))}
                                            </div>

                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </Popover>

                            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                                <div className="sm:text-center lg:text-left">
                                    <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                        <span className="block xl:inline">Mi kreiramo proizvode za vas</span>{' '}
                                        <span className="block text-indigo-600 xl:inline">L.P.B. promet</span>
                                    </h1>
                                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                        Osnovna delatnost je prerada papira, odnosno proizvodnja papirne galanterije. Samostalno smo formirali robnu marku “CAMELIA”, koja pokriva široku paletu proizvoda.
                                    </p>
                                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                        <div className="rounded-md shadow">
                                            <a
                                                href="#"
                                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                                            >
                                                Proizvodi
                                            </a>
                                        </div>
                                        <div className="mt-3 sm:mt-0 sm:ml-3">
                                            <a
                                                href="#"
                                                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                                            >
                                                Više o nama
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                        <img
                            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                            src={HeroImg}
                            alt="Slika firme"
                        />
                    </div>
                </div>
            </div>
        </>   
    )
}

export default Hero;