import React from 'react'
import { SiSimpleanalytics } from 'react-icons/si'
import { GiMultipleTargets } from 'react-icons/gi'
import { TbBulb } from 'react-icons/tb'
import  Img1 from '../assets/IMG_0909.jpg'

const text = [
    { icon:<SiSimpleanalytics className='h-16 w-auto mt-2 p-2 border-2 border-indigo-400 hover:border-white hover:bg-indigo-500 hover:text-white' />,title:"Tržište",description:"Proizvode plasiramo na domaćem i inostranom tržištu."},
    { icon:<GiMultipleTargets className=' h-16 w-auto mt-2 p-2 border-2 border-indigo-400 hover:border-white hover:bg-indigo-500 hover:text-white' />,title:"Tehnologija",description:"Koristimo najsavremeniju tehnologiju za proizvodnju i pakovanje proizvoda."},
    { icon:<TbBulb className=' h-16 w-auto mt-2 p-2 border-2 border-indigo-400 hover:border-white hover:bg-indigo-500 hover:text-white' />,title:"Inovacija",description:"Široka paleta proizvoda proširuje se proizvodima koji zadovoljavaju najzahtevnije potrošače."},
]
const CameliaSection = () => {
    return(
        <>
        <div className='container-full'>
            <div className='container flex flex-col mx-auto mt-10'>
                <div className='text-lg text-indigo-600 bold mx-auto my-auto flex font-semibold mb-2'>LPB Promet
                    <hr className='bg-blue-800 w-5 h-1.5 rounded my-auto mx-4 mr-2 mt-3' /><hr className='bg-indigo-600 w-10 h-1.5 rounded my-auto mt-3' />
                </div>
                <div className='container uppercase text-5xl font-bold text-center text-zinc-800'><h1>Camelia</h1></div>
                <p className='text-lg p-1 pl-3 font-medium text-zinc-800 text-left mt-5'>
                    Osnovna delatnost je prerada papira, odnosno proizvodnja papirne galanterije. Samostalno smo formirali robnu marku “CAMELIA”, koja pokriva široku paletu proizvoda. 
                </p>

                <div className='container p-3 mt-8 md:flex '>
                    <ul className='container flex-col space-y-10'>
                        {text.map((item) => (
                            <li key={item.title} className='flex mb-10 my-auto'>
                                <div className='text-indigo-600 mr-2'>{item.icon}</div>
                                <div className='flex flex-col text-zinc-600 px-1'><span className='text-3xl font-semibold text-zinc-800'>{item.title}</span>{item.description}</div>
                            </li>
                        ))}
                    </ul>
                    <img src={Img1} className="mt-10 md:mt-0 border-t-4 border-b-4 border-indigo-500 h-56 w-full object-cover sm:h-72 md:h-96 lg:h-auto"/>
                </div>
            </div>
        </div>

        </>
    )
}

export default CameliaSection;