import React from "react";
import Hero from "./Hero";
import Cards from "./Cards";
import CameliaSection from "./CameliaSection";
import Products from "./Products";
import VideoSection from "./VideoSection";
import ContactForm from "./ContactForm";

const Main = () => {
    return (
        <>
        <div className="container-full">
            <Hero />
            <div className="flex">
                <Cards />
            </div>
            <CameliaSection />
            <Products />
            <VideoSection />
            <ContactForm />
        </div>
        </>
    )
};

export default Main;