import React, { useState } from 'react'
import Img1 from '../assets/Proizvodnja-1.jpeg'
import { AiOutlinePlaySquare, AiOutlineCloseSquare } from 'react-icons/ai'
import ModalVideo from 'react-modal-video'

const VideoSection = () => {
    const [showModal, setShowModal] = React.useState(false);
    const [isOpen, setOpen] = useState(false)
    return (
        <div className='container-full flex-col my-10'>
            <div className='text-lg text-indigo-600 mx-auto justify-center my-5 flex font-semibold '>LPB Promet
                <hr className='bg-blue-800 w-5 h-1.5 rounded my-auto mx-4 mr-2 mt-3' /><hr className='bg-indigo-600 w-10 h-1.5 rounded my-auto mt-3' />
            </div>
            <div className='container-full bg-indigo-500 h-full'>
                <div className='relative container mx-auto h-full w-screen object-cover border-l-4 border-r-4 border-white '>
                    <img className='grayscale' src={Img1}></img>
                    <button className='bg-indigo-500 text-white h-18 p-4 absolute bottom-20 flex text-lg hover:bg-indigo-800 duration-300' onClick={() => (setShowModal(true), setOpen(true))}><AiOutlinePlaySquare className='-mt-1 h-8 w-auto text-white mr-2 hover:text-' />Pogledajte video</button>

                    {showModal ? (
                        <>
                            <div
                                className="justify-center items-center flex overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                            >
                                <div className="pl-24 my-6 max-w-3xl -mt-32 mx-auto w-full ">
                                    {/*content*/}
                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                        {/*header*/}
                                        <div className="flex items-start p-5 border-b border-solid border-slate-200 rounded-t">
                                            <h1 className="text-3xl font-semibold">
                                                Saznajte više o nama
                                            </h1>
                                            <button
                                                className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                                onClick={() => (setShowModal(false), setOpen(false))}
                                            >
                                                <span className="text-indigo-900 h-6 w-auto text-2xl">
                                                    <AiOutlineCloseSquare className='text-indigo-900' />
                                                </span>
                                            </button>
                                        </div>
                                        {/*body*/}
                                        <div className="p-6 h-80 w-auto ">
                                            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="u42mRAT5dIA" onClose={() => setOpen(false)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
                        </>
                    ) : null}

                </div>
            </div>

        </div>
    )
}

export default VideoSection;