import './App.css';
import Navbar from './components/Navbar';
import Main from './components/Main';
import ScrollButton from './components/ScrollButton';
import Footer from './components/Footer';

function App() {
  return (
    <h1 className="m-0">
      <Main />
      <ScrollButton />
      <Footer />
    </h1>
  );
}

export default App;
