import React from "react";
import { ImLocation } from 'react-icons/im';
import { MailIcon, PhoneIcon, LocationMarkerIcon } from '@heroicons/react/outline'

const services = [
    { name: "Prodaja", location: "Ibarski put 25, Donja Trepča 32215 Čačak", number: "+381 32 406 888", mail: "prodaja@camelia.rs", mailto: "mailto:prodaja@camelia.rs", tel: "telto: +381 32 406 888" },
    { name: "Direkcija", location: "Ibarski put 25, Donja Trepča 32215 Čačak", number: "+381 32 406 888", mail: "office@camelia.rs", mailto: "mailto:office@camelia.rs", tel: "telto: +381 32 406 888" },
    { name: "Porudžbine", location: "Ibarski put 25, Donja Trepča 32215 Čačak", number: "+381 32 406 888", mail: "porudzbine@camelia.rs", mailto: "mailto:porudzbine@camelia.rs", tel: "telto: +381 32 406 888" },
    { name: "Tehnička služba", location: "Ibarski put 25, Donja Trepča 32215 Čačak", number: "+381 32 406 888", mail: "it@camelia.rs", mailto: "mailto:it@camelia.rs", tel: "telto: +381 32 406 888" }
]

const MapSection = () => {
    return (
        <>
            <div className="container lg:container-full mx-auto flex flex-col lg:flex-row">
                <iframe className="w-full border-t-4 border-b-4 border-indigo-500 flex flex-col lg:flex-row" width="900" height="700" src="https://maps.google.com/maps?q=Camelia,%20%D0%98%D0%B1%D0%B0%D1%80%D1%81%D0%BA%D0%B8%20%D0%BF%D1%83%D1%82,%2032212%20Cacak%20City,%20Serbia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                <div className="w-full dark:bg-zinc-900">
                    <div className="py-10 grid grid-column-1 md:grid-cols-2 ">
                        {services.map((item) => (
                            <div className="flex flex-col text-white text-lg mx-auto space-y-4 my-10 pb-10" key={item.name}>
                                <div className="text-3xl font-semibold mb-2 border-l-4 border-white px-3 w-fit h-12 pt-1 mx-auto bg-indigo-800 text-white hover:bg-white hover:text-indigo-900 duration-500">{item.name}</div>
                                <a href="https://www.google.com/maps/place/Camelia/@43.8936563,20.4374689,17z/data=!3m1!4b1!4m5!3m4!1s0x47576d925f51c1db:0x7b0e153da4eb6919!8m2!3d43.8936525!4d20.4396576"><div className="flex justify-center text-left mx-auto hover:bg-white hover:text-indigo-900 duration-500 w-fit transform ease-in-out hover:-transform-y-1 hover:scale-110"><ImLocation className="mt-1 h-5 w-auto" />{item.location}</div></a>
                                <a href={item.tel}><div className="flex justify-center text-left mx-auto hover:bg-white hover:text-indigo-900 duration-500 w-fit transform ease-in-out hover:-transform-y-1 hover:scale-110"><PhoneIcon className="mt-1 h-5 w-auto" />{item.number}</div></a>
                                <a href={item.mailto}><div className="flex justify-center text-left mx-auto hover:bg-white hover:text-indigo-900 duration-500 w-fit transform ease-in-out hover:-transform-y-1 hover:scale-110"><MailIcon className="mt-1 h-5 w-auto" />{item.mail}</div></a>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

        </>
    )
}

export default MapSection;