import React from "react";
import { BsChevronRight } from "react-icons/bs"
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa"
import MapSection from "./MapSection";

const navigation = [
    { name: 'Početna', href: '#' },
    { name: 'Proizvodi', href: '#' },
    { name: 'Kompanija', href: '#' },
    { name: 'Karijera', href: '#' },
    { name: 'Vesti', href: '#' },
    { name: 'Kontakt', href: '#' },
]

const Footer = () => {
    return (
        <section className="container-full">
            <div className="container-full w-full my-10">
                <MapSection/>
            </div>
            <footer class="p-4 bg-white sm:p-6 dark:bg-indigo-900 mt-10">
                <div class="flex-col lg:flex lg:flex-row lg:justify-between px-4">
                    <div class="mb-6 md:mb-0 lg:mr-10">
                        <a href="https://camelia.rs" className="flex justify-center lg:justify-start">
                            <img src="/lpb.png" className="mr-3 h-24 " alt="LPB Logo" />
                            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
                        </a>
                        <span className="text-white ml-10 pt-5 pb-5 ">
                            <ul className="space-y-6 md:space-y-0 lg:space-y-2 md:space-x-6 lg:space-x-0 border-t-2 border-b-2 border-white lg:border-b-0 py-4 mx-auto mb-10 md:mb-0 md:flex md:flex-row lg:flex-col">
                                {navigation.map((item) => (
                                    <li className="flex"><BsChevronRight className="h-5 mt-1 mr-2 w-auto" /><a key={item.name} href={item.href} className="font-medium text-white hover:font-semibold transition ease-in-out delay-150 hover:translate-x-2 hover:scale-110 duration-200">
                                        {item.name}
                                    </a></li>
                                ))}
                            </ul>
                        </span>
                    </div>
                    <div class="flex flex-col md:flex-row w-full lg:w-6/12 pt-10 lg:pt-4">
                        <div className="md:border-b-0 border-b-2 border-white md:w-6/12 md:p-5 pb-10 md:pb-0">
                            <h2 class="mb-6 text-xl font-semibold text-gray-900 uppercase dark:text-white md:border-b-2 md:border-white md:pb-10">Više o nama</h2>
                            <div class="text-zinc-300 dark:text-zinc-300 font-medium lg:px-2">
                                Preduzeće za proizvodnju promet i usluge “L.P.B. PROMET” d.o.o. je privatno preduzeće osnovano 2006. godine sa sedištem u Čačku.
                                Osnovna delatnost je prerada papira, odnosno proizvodnja papirne galanterije.
                                Samostalno smo formirali robnu marku “CAMELIA”, koja pokriva široku paletu proizvoda.
                            </div>
                        </div>
                        <div className="border-b-0 border-b-2 md:border-white mt-10 md:mt-5 lg:w-6/12">
                            <h2 class="mb-6 text-xl font-semibold text-gray-900 uppercase dark:text-white md:border-b-2 md:border-white md:pb-10">Najnovija obaveštenja!</h2>
                            <div class="text-gray-600 dark:text-gray-400 space-y-5">
                                <p>Prijavite se za naše najnovije vesti i članke. Nećemo vam slati neželjenu poštu.</p>
                                <div className="space-y-5 relative overflow-hidden">
                                    <input type="email" id="helper-text" aria-describedby="helper-text-explanation" class="bg-indigo-50 border border-indigo-300 text-gray-900 text-sm rounded-lg 
                                                                                                                            focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5  
                                                                                                                            dark:bg-indigo-300 dark:border-white dark:placeholder-zinc-500 dark:text-white dark:focus:ring-indigo-500 dark:focus:border-indigo-500"
                                        placeholder="name@example.com" />
                                    <button type="submit" class="text-white absolute right-0 bottom-0 overflow-hidden bg-indigo-700 
                                                            hover:bg-white focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-4 py-2 
                                                            dark:bg-white dark:hover:bg-indigo-700 dark:focus:ring-indigo-800 w-auto h-11"><BsChevronRight className="w-auto h-5 text-indigo-800" />
                                    </button>

                                    <p id="helper-text-explanation" class="hidden mt-2 text-sm text-zinc-500 dark:text-zinc-400">We’ll never share your details. Read our <a href="#" class="font-medium text-white hover:underline dark:text-white">Privacy Policy</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="my-6 border-gray-200 sm:mx-auto dark:border-zinc-700 lg:my-8" />
                <div class="sm:flex sm:items-center sm:justify-between text-center">
                    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="/pocetna" class="hover:underline">LPB promet™</a>. All Rights Reserved.
                    </span>
                    <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0 justify-center">
                        <a href="https://www.facebook.com/LPBPromet/"><FaFacebookF size={20} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 duration-200 md:text-white text-zinc-400 rounded p-2 h-9 w-auto" /></a>
                        <a href="https://rs.linkedin.com/company/lpbprometdoo?trk=similar-pages&original_referer=https%3A%2F%2Fcamelia.goldensolution.rs%2F"><FaLinkedinIn size={20} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 duration-200 md:text-white text-zinc-400 rounded p-2 h-9 w-auto" /></a>
                        <a href="https://www.instagram.com/camelia_cacak/?igshid=YmMyMTA2M2Y="><FaInstagram size={20} className="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-150 duration-200 md:text-white text-zinc-400 rounded p-2 h-9 w-auto" /></a>
                    </div>
                </div>
            </footer>
        </section>
    )
}

export default Footer;