import React from "react";

const ContactForm = () => {
    return (
        <>
            <div className="container-full w-full">
                <div className="container flex-col mx-auto">
                    <div className='text-xl text-indigo-600 mx-auto justify-center my-5 flex font-semibold'>Camelia
                        <hr className='bg-blue-800 w-5 h-1.5 rounded my-auto mx-4 mr-2 mt-3' /><hr className='bg-indigo-600 w-10 h-1.5 rounded my-auto mt-3' />
                    </div>
                    <div className="text-3xl font-bold p-2 text-center">
                        <h1>Široka paleta proizvoda </h1>
                    </div>
                    <p className="text-normal font-semibold text-zinc-700 text-center">
                        Svoje proizvode samostalno distribuiramo, kao i proizvode partnerskih kompanija, što predstavlja dodatnu delatnost firme.
                        Za sve informacije i mogućnosti za partnerstvo i saradnju sa našom kompanijom kontatirajte nas!
                    </p>
                </div>
                <div className="container-full lg:px-20 bg-indigo-500">
                        <form className="bg-zinc-900 my-10 px-10 pb-10 border-l-4 border-r-4 border-white ">
                            <div className='text-4xl text-white py-5 mx-auto justify-center my-5 flex font-semibold'>Kontaktirajte nas
                                <hr className='bg-blue-800 w-5 h-1.5 rounded my-auto mx-4 mr-2 mt-6 rotate-90' /><hr className='bg-indigo-600 w-10 h-1.5 rounded my-auto mt-6 rotate-90' /><hr className='bg-blue-800 w-5 h-1.5 rounded my-auto mx-4 mr-2 mt-6 rotate-90' />
                            </div>
                            <div class="grid xl:grid-cols-2 xl:gap-6">
                            <div class="relative z-0 w-full mb-6 group">
                                <input type="email" name="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                <label for="floating_email" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email adresa</label>
                            </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" name="floating_name" id="floating_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                    <label for="floating_name" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Name</label>
                                </div>
                            </div>
                            <div class="grid xl:grid-cols-2 xl:gap-6">
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" name="floating_company" id="floating_company" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                    <label for="floating_company" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Kompanija (opciono)</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="floating_phone" id="floating_phone" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                                    <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Broj telefona (123-456-7890)</label>
                                </div>
                            </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Poruka</label>
                                    <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Leave a comment..."></textarea></div>
                            <button type="submit" class="md:flex md:mx-auto  text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Pošalji</button>
                        </form>
                    </div>
            </div>
        </>
    )
}

export default ContactForm;