import React from "react";

const Products = () => {
    return(
        <>
            <div className='container-full'>
                <div className='container flex flex-col mx-auto mt-10'>
                    <div className='text-lg text-indigo-600 bold mx-auto my-auto flex font-semibold'>LPB Promet
                    <hr className='bg-blue-800 w-5 h-1.5 rounded my-auto mx-4 mr-2 mt-3' /><hr className='bg-indigo-600 w-10 h-1.5 rounded my-auto mt-3' />
                </div>
                <div className="text-2xl font-semibold p-4 text-left">
                    <h1>Naša proizvodnja u osnovnoj delatnosti nudi sledeće proizvode: </h1>
                </div>
                </div>
            </div>
        </>
    )
}

export default Products;